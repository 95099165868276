<template>
  <div class="compon_echarts">
    <div class="compon_echarts_enterprise" ref="echarts"></div>
  </div>
</template>
  
  <script>
// 引入echarts
import * as echarts from 'echarts'

export default {
  name: 'ComponEcharts',
  mounted() {
    // this.renderEcharts()
  },
  props: {
    chartInfo: {
      type: Object,
      default: {}
    }, // 数据
    tabindex: {
      type: Number,
      default: 0
    },
    dateIndex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    'chartInfo.nameEchart'(val) {
      const myChart = echarts.init(this.$refs.echarts)
      myChart.dispose()
      this.$nextTick(() => {
        this.renderEcharts()
      })
    },
    'chartInfo.chartArr'(newval, oldval) {
      const myChart = echarts.init(this.$refs.echarts)
      myChart.dispose()

      this.$nextTick(() => {
        this.renderEcharts()
      })
    }
  },
  methods: {
    renderEcharts() {
      this.chartInfo.chartArr = this.alignmentArray(this.chartInfo.chartArr, 'list')

      const myChart = echarts.init(this.$refs.echarts)
      let arr1 = []
      let arr2 = []
      let min = 0
      let max = 0
      let interval = 0 // 设置Y轴刻度间隔
      let list = []
      let datas = []

      if (this.chartInfo.type == 0) {
        list = []
        console.log(this.chartInfo.chartArr)

        this.chartInfo.chartArr.forEach((el, i) => {
          datas.push({
            value: el.num
          })
          list.push({
            value: el.num,
            itemStyle: { color: el.color },
            img: this.chartInfo.img
          })
        })

        arr1.push({
          name: '客户经理', // 为系列取名
          data: list,
          silent: false, // 确保事件可以触发
          type: 'bar',
          silent: false, // 允许交互
          barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
          barMaxWidth: '50px', // 限制柱子的最大宽度

          markPoint: {
            symbol: 'circle' // 设置拐点小圆点
            // 其他配置项...
          },

          symbolSize: 10 // 设置拐点小圆点大小
        })

        arr2 = this.chartInfo.chartArr.map((item) => item.unit)
      } else {
        this.chartInfo.chartArr.forEach((el) => {
          list = []
          //走访需求
          if (this.tabindex == 3) {
            el.list.forEach((o) => {
              datas.push({
                value: o.requirementCount
              })
              list.push({
                list: o.visitsCharResultList,
                value: o.requirementCount || 0, //字段不同
                img: this.chartInfo.img
              })
            })
          } else if (this.tabindex == 4) {
            el.list.forEach((o) => {
              datas.push({
                value: o.budgetCost
              })
              list.push({
                list: o.visitsCharResultList,
                value: o.budgetCost || 0, //字段不同
                img: this.chartInfo.img
              })
            })
          } else {
            el.list.forEach((o) => {
              datas.push({
                value: o.count
              })
              list.push({
                list: o.visitsCharResultList,
                value: o.count || 0, //字段不同
                img: this.chartInfo.img
              })
            })
          }

          if (this.chartInfo.type == 1) {
            arr1.push({
              name: el.unit, // 为系列取名
              data: list,
              silent: false, // 确保事件可以触发
              type: this.chartInfo.chartType,
              barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
              barMaxWidth: '50px', // 限制柱子的最大宽度
              color: el.color, // 设置折线颜色

              markPoint: {
                symbol: 'circle' // 设置拐点小圆点
                // 其他配置项...
              },
              symbolSize: 10 // 设置拐点小圆点大小
            })
          } else if (this.chartInfo.type == 2) {
            arr1.push({
              name: el.unit, // 为系列取名
              data: list,
              silent: false, // 确保事件可以触发
              type: this.chartInfo.chartType,

              barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
              barMaxWidth: '50px', // 限制柱子的最大宽度
              color: el.color,
              // 面积颜色

              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: el.color }, // 渐变起始颜色
                  { offset: 1, color: 'rgba(255, 255, 255, 0)' } // 渐变结束颜色（透明）
                ])

                //   color: el.color
              },

              markPoint: {
                symbol: 'circle' // 设置拐点小圆点
                // 其他配置项...
              },
              symbolSize: 10 // 设置拐点小圆点大小
            })
          }

          arr2 = el.list.map((item) => item.time)
        })
      }
      let zoom = []
      if (this.tabindex == 1 && this.dateIndex == 1) {
        zoom = [
          {
            type: 'slider', // inside 内置型  slider 滑动条
            show: true,
            start: 30,
            bottom: '0%',
            end: 100
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: 30,
            end: 100
          }
        ]
      } else if (this.tabindex != 1 && this.dateIndex == 1) {
        zoom = [
          {
            type: 'slider', // inside 内置型  slider 滑动条
            show: true,
            start: 10,
            bottom: '0%',
            end: 100
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: 10,
            end: 100
          }
        ]
      } else {
        zoom = [
          {
            type: 'slider', // inside 内置型  slider 滑动条
            show: true,
            start: 70,
            bottom: '0%',
            end: 100
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: 70,
            end: 100
          }
        ]
      }

      max = Math.ceil(Number(Math.max(...datas.map((item) => item.value))))
      // 拿到数据的最小值  -- 向下取整
      min = Math.floor(Number(Math.min(...datas.map((item) => item.value))))
      // 计算分割间距
      if (!max) {
        min = 0
        max = 12
      }
      if (max == min) {
        interval = Math.ceil((max - 0) / 6)
      } else {
        interval = Math.ceil((max - min) / 6)
      }
      if (min - interval > 0) {
        min = min - interval
      }

      let option
      option = {
        // 可以显示整个图表
        dataZoom: zoom,
        title: {
          subtext: this.chartInfo.nameEchart, // 副标题文本
          left: 'left', // 标题水平居中
          top: 14,
          textStyle: {
            // 标题文本样式
          },
          subtextStyle: {
            // 副标题样式
            fontStyle: 'normal', // 字体风格
            fontWeight: 'bold', // 字体粗细
            fontSize: 12, // 字体大小，可以根据需要调整
            color: '#333333' // 字体颜色
          }
        },
        color: '#00BDFF',
        tooltip: {
          trigger: 'axis',
          show: true, // 是否显示提示框，默认为 true
          // trigger: 'item', // 触发类型，可选值: 'item'（数据项触发），'axis'（坐标轴触发），'none'（不触发）
          backgroundColor: '#fff', // 提示框背景色
          padding: [3], // 内边距
          textStyle: {
            // 文本样式
            color: '#fff', // 文本颜色
            fontSize: 12 // 文本字号
          },
          // 悬浮框提示
          formatter(params) {
            // 处理时间展示格式
            return `<div style="min-width:200px; height:68px; color:#fff; background: #fff; font-family: PingFang SC; padding: 10px; box-sizing: border-box;">
                <div style="display:flex; margin-bottom: 4px">
                   <img width="38" height="38" src="${params[0].data.img}" alt="" />

                  <div style="display:flex; flex-direction: column; justify-content: space-between; margin-left: 8px">
                    <span style="font-size: 12px; color: #333; font-weight: 600;">${params[0].name}${params[0].seriesName}</span>
                    <span style="font-size: 12px; color: ${params[0].color}; font-weight: 400;font-size:14px">${params[0].value}<span style="color:#333333;font-size:12px">个</span></span>
                  </div>
                </div>

              </div>
              `
          },
          // 辅助线
          axisPointer: {
            type: 'line', // 指示器类型，可选值: 'line'（直线指示器），'shadow'（阴影指示器），'cross'（十字准星指示器）
            lineStyle: {
              color: '#1890FF', // 辅助线颜色
              type: 'dashed'
            },
            crossStyle: {
              // 十字准星指示器样式设置
              color: '#aaa' // 线条颜色
            },
            shadowStyle: {
              // 阴影指示器样式设置
              color: 'rgba(150,150,150,0.3)' // 阴影颜色
            }
          }
        },
        xAxis: {
          type: 'category',

          axisLine: {
            //y轴线的配置
            show: true, //是否展示
            lineStyle: {
              color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
              width: 1, //y轴线的宽度
              type: 'dashed' //y轴线为实线
            }
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: '#333333' //Y轴内容文字颜色
            },
            interval: 0 // 设置为0表示全部显示x轴坐标
          },
          // boundaryGap: false,
          nameTextStyle: {
            verticalAlign: 'bottom',
            lineHeight: 10,
            backgroundColor: '#DCE9FE'
          },
          data: arr2
        },
        yAxis: {
          type: 'value',
          // data: [0, 2, 4, 6, 16000, 20000, 24000],
          axisLine: {
            //y轴线的配置
            show: true, //是否展示
            lineStyle: {
              color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
              width: 1, //y轴线的宽度
              type: 'dashed' //y轴线为实线
            }
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              interval: 1, // 设置为1表示隔一个标签显示一个标签，从而调整分割线的间距
              color: '#333333', //Y轴内容文字颜色
              fontSize: 10 // 设置y轴标题文字大小为25
            }
          },
          // y轴分割线
          splitLine: {
            show: true, // 显示分隔线
            lineStyle: {
              type: 'dashed', // 设置分隔线为虚线
              color: '#E4E4E4'
            }
          },
          min,
          max,
          interval // 设置Y轴刻度间隔
        },

        series: arr1
      }
      let that = this
      option && myChart.setOption(option)
      myChart.on(
        'mouseover',
        this.debounce(function (params) {
          if (that.tabindex == 2 && params.data.list) {
            myChart.setOption({
              tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(0, 0, 0, 0)', // 背景透明
                borderWidth: 0, // 移除边框
                show: true,
                formatter: function (tooltipParams) {
                  let listItems = ''
                  for (let i = 0; i < params.data.list.length; i++) {
                    listItems += `  <div style="display:flex; width:50%;  margin-bottom: 4px" >
                      <span style="font-size: 12px;width:40px; color: #333; font-weight: 600;">${params.data.list[i].customerName}</span>
                      <span style="font-size: 12px; color: #333; font-weight: 600;margin-left:10px">${params.data.list[i].num}</span>
                    </div>`
                  }
                  // 只显示当前鼠标悬停的系列信息
                  return `<div style="min-width:200px; height:68px; color:#fff; background: #fff; font-family: PingFang SC; padding: 10px; box-sizing: border-box;">
                <div>
                 
                  <div style="display:flex; justify-content: space-between;">
                    <span style="font-size: 12px; color: #333; font-weight: 600;">${params.name}${params.seriesName}</span>
                    <span style="font-size: 12px; color: ${params.color}; font-weight: 400;font-size:14px">${params.value}<span style="color:#333333;font-size:12px">次</span></span>
                  </div>
                  <div>
                  
                
                  
                    
                    </div>
                  <div  style="display:flex;   flex-wrap: wrap; justify-content: space-between;width:100%; margin-bottom: 4px">
                      ${listItems}
                    </div>
                </div>

              </div>`
                }
              }
            })
          } else if (that.tabindex == 4 || that.tabindex == 1 || that.tabindex == 0) {
            myChart.setOption({
              tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(0, 0, 0, 0)', // 背景透明
                borderWidth: 0, // 移除边框
                show: true,
                formatter: function (tooltipParams) {
                  // 只显示当前鼠标悬停的系列信息
                  return `<div style="min-width:200px; height:68px; color:#fff; background: #fff; font-family: PingFang SC; padding: 10px; box-sizing: border-box;">
                <div style="display:flex; margin-bottom: 4px">
                   <img width="38" height="38" src="${params.data.img}" alt="" />

                  <div style="display:flex; flex-direction: column; justify-content: space-between; margin-left: 8px">
                    <span style="font-size: 12px; color: #333; font-weight: 600;">${params.name}${
                    params.seriesName
                  }</span>
                    <span style="font-size: 12px; color: ${
                      params.color
                    }; font-weight: 400;font-size:14px">${
                    params.value
                  }<span style="color:#333333;font-size:12px">${
                    that.tabindex == 4 ? '万' : '人'
                  }</span></span>
                  </div>
                </div>

              </div>`
                }
              }
            })
          } else {
            myChart.setOption({
              tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(0, 0, 0, 0)', // 背景透明
                borderWidth: 0, // 移除边框
                show: true,
                formatter: function (tooltipParams) {
                  // 只显示当前鼠标悬停的系列信息
                  return `<div style="min-width:200px; height:68px; color:#fff; background: #fff; font-family: PingFang SC; padding: 10px; box-sizing: border-box;">
                <div style="display:flex; margin-bottom: 4px">
                   <img width="38" height="38" src="${params.data.img}" alt="" />

                  <div style="display:flex; flex-direction: column; justify-content: space-between; margin-left: 8px">
                    <span style="font-size: 12px; color: #333; font-weight: 600;">${params.name}${params.seriesName}</span>
                    <span style="font-size: 12px; color: ${params.color}; font-weight: 400;font-size:14px">${params.value}<span style="color:#333333;font-size:12px">个</span></span>
                  </div>
                </div>

              </div>`
                }
              }
            })
          }
        }, 100)
      ) // 100ms 防抖

      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },

    alignmentArray(arr = [], key) {
      let maxLength = 0
      let time = ''
      if (!Array.isArray(arr)) {
        return []
      }

      // 计算最大长度
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i]

        if (Array.isArray(item[key]) && item[key].length > 0) {
          if (item[key].length > maxLength) {
            maxLength = item[key].length
            time = item[key][maxLength - 1].time
          }
        }
      }

      // 赋值
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i]

        if (Array.isArray(item[key]) && item[key].length > 0) {
          if (item[key].length < maxLength) {
            for (let j = 0; j <= maxLength - item[key].length; j++) {
              item[key].push({
                time: time
              })
            }
          }
        }
      }

      return arr
    },
    debounce(func, wait) {
      let timeout
      return function (...args) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          func.apply(this, args)
        }, wait)
      }
    }
  }
}
</script>
  
  
  <style scoped lang="less">
.compon_echarts {
  width: 100%;
  overflow: hidden;

  .compon_echarts_enterprise {
    height: 440px;
    overflow: hidden;
    width: 100%;
  }
}
</style>
  