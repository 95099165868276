import request from "@/utils/request"

// 获取工作台数据
export function getCockpitData(data) {
  return request({
    url: '/workbench/getDataStatisticsResult',
    method: 'get',
    params: data
  })
}

// 客户数据折线图
export function getUserCurveChart(data) {
  return request({
    url: '/workbench/getUserCurveChart',
    method: 'get',
    params: data
  })
}

// 获取用户数据
export function getServiceDataResult(data) {
  return request({
    url: '/workbench/getServiceDataResult',
    method: 'get',
    params: data
  })
}
//走访次数柱状图
export function getVisitResult(data) {
  return request({
    url: '/workbench/getVisitResult',
    method: 'get',
    params: data
  })
}
//走访需求次数柱状图
export function getVisitRequirementResult(data) {
  return request({
    url: '/workbench/getVisitRequirementResult',
    method: 'get',
    params: data
  })
}
//案例数据柱状图
export function getPlanResult(data) {
  return request({
    url: '/workbench/getPlanResult',
    method: 'get',
    params: data
  })
}

// 企业榜单统计
export function getBusinessVisitList(data) {
  return request({
    url: '/workbench/getBusinessVisitList',
    method: 'post',
    data
  })
}

//获取管理员后台工作台统计数据概览
export function getAdminOverviewResult() {
  return request({
    url: '/workbench/getAdminOverviewResult',
    method: 'get',
    
  })
}
//获取所有的二级认领单位数据--根据系统用户的权限
export function getTwoLevelUnitByUserId() {
  return request({
    url: '/customerManager/getTwoLevelUnitByUserId',
    method: 'get',
    
  })
}
//管理员折线图数据
export function getRequirementCurveChart(data) {
  return request({
    url: '/workbench/getRequirementCurveChart',
    method: 'post',
    data
  })
}

// 走访榜单
export function getVisitList(data) {
  return request({
    url: '/workbench/getVisitList',
    method: 'post',
    data
  })
}
