/**
 * @author: coderLh
 * @date: 2025/3/5
 * @desc: 配置表格
 *
 * 参数说明：
 * label代表列名，key代表列的key，width代表列的宽度，prop代表列的属性，align代表列的对齐方式
 * dataType {String}  text代表文本类型，img代表图片类型 btn代表按钮类型
 */

// 走访榜单的配置
const rangingConfigData = [
  {
    dataType: 'text',
    label: '排名',
    key: '1',
    width: '50',
    type: 'index',
    align: 'center'
  },
  {
    dataType: 'img',
    label: '头像',
    key: '2',
    width: 80,
    prop: 'customerHead',
    align: 'center',
  },
  {
    dataType: 'text',
    label: '姓名',
    key: '3',
    width: '',
    prop: 'customerName',
    align: 'center',
    // render: (h, data) => {
    //   return h('div', [
    //     h('span', data.row.name)
    //   ])
    // }
  },
  {
    dataType: 'sort',
    label: '走访次数',
    key: '4',
    width: '',
    prop: 'visitNum',

    align: 'center',
  },
  {
    dataType: 'sort',
    label: '走访需求',
    key: '5',
    width: '',
    prop: 'visiRequirementNum',
    align: 'center',
  },
  {
    dataType: 'sort',
    label: '最近走访时间',
    key: '6',
    width: 140,
    prop: 'time',
    align: 'center',
  }
]
const ConfigData = [
  {
    dataType: 'text',
    label: '排名',
    key: '1',
    width: '50',
    type: 'index',
    align: 'center'
  },
  {
    dataType: 'text',
    label: '企业名称',
    key: '2',
   
    prop: 'companyFullName',
    align: 'center',
  },

  {
    dataType: 'sort',
    label: '走访次数',
    key: '4',
    width: '',
    prop: 'number',
    align: 'center',
  },
  
  {
    dataType: 'sort',
    label: '最近走访时间',
    key: '6',
    width: 180,
    prop: 'time',
    align: 'center',
  }
]
// 走访榜单的假数据
const rangingData = [
  {
    ranging:'1',
    name: '张三',
    avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
    visitCount: '10',
    visitRequire: '1',
    visitTime: '2025-03-05'
  },
  {
    ranging:'2',
    name: '李四',
    avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
    visitCount: '10',
    visitRequire: '1',
    visitTime: '2025-03-05'
  },
  {
    ranging:'3',
    name: '王五',
    avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
    visitCount: '10',
    visitRequire: '1',
    visitTime: '2025-03-05'
  },
]

export default {
  rangingConfigData,
  rangingData,
  ConfigData
}
