<template>
  <div class="compon_table">
    <el-table
      :data="tableData"
      style="width: 100%"
       @sort-change="sortChange"
      :header-cell-style="{'color': '#333333', 'font-size': '14px', 'font-weight': '400'}"
    >
      <template v-for="(item, index) in tableConfig">
        <el-table-column
          :prop="item.prop"
          :key="index"
          :label="item.label"
          :width="item.width"
          :align="item.align"
          :type="item.type"
          v-if="item.dataType == 'text'"
        ></el-table-column>
        <el-table-column v-else-if="item.dataType == 'img'" :key="index" :label="item.label"
                         :width="item.width"
                         :align="item.align">
          <template slot-scope="scope">
            <img class="avatar" :src="scope.row.customerHead">
          </template>
        </el-table-column>
        <el-table-column
          v-else
          sortable
          :prop="item.prop"
          :key="index"
          :label="item.label"
          :width="item.width"
          :align="item.align"
          :type="item.type"
        >
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>

export default {
  name: "ComponTable",
  props: {
    tableData: {
      type: Array,
      default: () => []
    },// 数据
    tableConfig: {
      type: Array,
      default: () => []
    }, //配置数据
  },
  watch:{
    tableData: {
      deep: true, // 深度监听数组变化
      handler(newItems, oldItems) {
       
        // 在这里执行更新操作
      }
    }
  },
  methods: {
    // 排序
    sortChange(val) {
      this.$emit('sortChange', val)
    }
  }
}
</script>

<style scoped lang="less">
.compon_table{
  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 2px;
  }
}
</style>
