<template>
  <div class="workbench">
    <!-- <topOne v-if="roleId == 1"></topOne> -->

    <topThree v-if="roleId == 4"></topThree>
    <topTwo v-else></topTwo>
    <!-- <div v-if="roleId == 1">
      <div class="dataEcharts">
        <chart1></chart1>
        <chart2></chart2>
      </div>
      <div class="dataEcharts">
        <chart3></chart3>
        <chart4></chart4>
      </div>
    </div> -->

  </div>
</template>

<script>

import chart1 from './components/chart1.vue'
import chart2 from './components/chart2.vue'
import chart3 from './components/chart3.vue'
import chart4 from './components/chart4.vue'
import topOne from './components/topOne.vue'
import topTwo from './components/topTwo.vue'
import topThree from './components/topThree.vue'


export default {
  name: 'cockpit',
  components: {
    // chart1,
    // chart2,
    // chart3,
    // chart4,
    // topOne,
    topTwo,
    topThree,
  },
  data() {
    return {}
  },
  computed: {
    roleId() {
      return JSON.parse(sessionStorage.getItem('roleId'))
    },
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.active {
  background: #85b6ff;
  color: #fff;
  cursor: pointer;
}


.workbench {
  width: 100%;
  height: 100%;
  background: #f6f8f9;
  box-sizing: border-box;
  overflow-y: auto;
  /* 滚动条整体部分 */

  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }

  /* 滚动条里面的滑块 */

  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }

  .dataEcharts {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
