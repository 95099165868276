<template>
  <div class="three_cai">
    <div class="top">
      <div class="login_info">
        <img src="../../../assets/image/total_pre.png" alt="" />
        <div class="info">
          <span>数据总览</span>
        </div>
      </div>
      <div class="right_name">
        <span>每天上午9点数据更新</span>
      </div>
    </div>
    <div class="dataTxt">
      <div class="ul_flex">
        <div class="ul_flex_up">
          <div @click="companyClick('4')" class="li_center">
            <div class="icon">
              <img class="icon_img" src="../../../assets/image/Group 1142815023.png" alt="" />
            </div>
            <div class="data_info">
              <span class="title">客户数据</span>
              <div class="numInfo">
                <span class="num color1">{{
                  cockpitData.customerDataCount == null ? 0 : cockpitData.customerDataCount
                }}</span>
                <span class="unit">个</span>
                <span>
                  <!-- <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.companyWeekNum == null ? 0 : cockpitData.companyWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span> -->
                </span>
              </div>
            </div>
          </div>
          <div @click="cockpitClick()" class="li_center" style="margin-bottom: 0px">
            <div class="icon">
              <img class="icon_img" src="../../../assets/image/Group 1142814542.png" alt="" />
            </div>
            <div class="data_info">
              <span class="title">客户经理</span>
              <div class="numInfo">
                <span class="num color4">{{
                  cockpitData.customerManagerCount == null ? 0 : cockpitData.customerManagerCount
                }}</span>

                <span>
                  <span class="unit">个</span>
                  <!-- <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.userWeekNum == null ? 0 : cockpitData.userWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span> -->
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="ul_flex_center">
          <div @click="vitisClick()" class="li_two">
            <div class="icon">
              <img class="icon_img" src="../../../assets/image/Group 1142814742.png" alt="" />
            </div>
            <div class="data_info">
              <span class="title">走访数据</span>
              <div class="numInfo">
                <span class="num color2">{{
                  cockpitData.visitDataCount == null ? 0 : cockpitData.visitDataCount
                }}</span>
                <span class="unit">个</span>
                <span>
                  <!-- <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.visitWeekNum == null ? 0 : cockpitData.visitWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span> -->
                </span>
              </div>
            </div>
          </div>

          <div class="bottom_flex">
            <div @click="cockpiturl" class="li_two_bottom">
              <div class="icon">
                <img class="icon_img" src="../../../assets/image/Group 1142815037.png" alt="" />
              </div>
              <div class="data_info">
                <span class="title">走访需求</span>
                <div class="numInfo">
                  <span class="num color5">{{
                    cockpitData.visitDemandCount == null ? 0 : cockpitData.visitDemandCount
                  }}</span>
                  <span class="unit">个</span>
                  <span>
                    <!-- <span class="add">本周新增</span>
                    <span class="addNum">{{
                      cockpitData.visitRequirementWeekNum == null
                        ? 0
                        : cockpitData.visitRequirementWeekNum
                    }}</span>
                    <span class="addIcon">
                      <i class="el-icon-top"></i>
                    </span> -->
                  </span>
                </div>
              </div>
            </div>
            <div class="li_two_bottom">
              <div class="icon">
                <img class="icon_img" src="../../../assets/image/Group 1142814504.png" alt="" />
              </div>
              <div class="data_info">
                <span class="title">预算费用</span>
                <div class="numInfo">
                  <span class="num color6">{{
                    cockpitData.totalBudget == null ? 0 : cockpitData.totalBudget
                  }}</span>

                  <span class="unit">万</span>
                  <span>
                    <!-- <span class="add">本周新增</span>
                    <span class="addNum">{{
                      cockpitData.weekBudget == null ? 0 : cockpitData.weekBudget
                    }}</span>
                    <span class="addIcon">
                      <i class="el-icon-top"></i>
                    </span> -->
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ul_flex_up">
          <div @click="companyClick('2')" class="li_center">
            <div class="icon">
              <img class="icon_img" src="../../../assets/image/Group 1142815034.png" alt="" />
            </div>
            <div class="data_info">
              <span class="title">案例数据</span>
              <div class="numInfo">
                <span class="num color3">{{
                  cockpitData.caseDataCount == null ? 0 : cockpitData.caseDataCount
                }}</span>
                <span class="unit">个</span>
                <span>
                  <!-- <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.planWeekNum == null ? 0 : cockpitData.planWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span> -->
                </span>
              </div>
            </div>
          </div>

          <div @click="companyClick('3')" class="li_center" style="margin-bottom: 0px">
            <div class="icon">
              <img class="icon_img" src="../../../assets/image/Group 1142815038.png" alt="" />
            </div>
            <div class="data_info">
              <span class="title">潜在线索</span>
              <div class="numInfo">
                <span class="num color7">{{
                  cockpitData.potentialLeadsCount == null ? 0 : cockpitData.potentialLeadsCount
                }}</span>
                <span class="unit">个</span>
                <span>
                  <!-- <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.diagnosisWeekNum == null ? 0 : cockpitData.diagnosisWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span> -->
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="context_flex">
      <div class="context_flex_left">
        <div class="context_flex_left_top">
          <span>请选择认领单位</span>
          <img src="../../../assets/image/85.png" alt="" />
        </div>
        <div class="auto_checkgroud">
          <el-checkbox-group v-model="checkList" @change="listchange">
            <div v-for="(item, index) in leftList" :key="index">
              <el-checkbox :label="item.id">
                <div class="label_flex">
                  <div class="left_back" :style="`background: ${item.color};`"></div>
                  <span>{{ item.unit }}</span>
                </div>
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
      </div>
      <div class="left_border"></div>
      <div class="context_flex_right">
        <div class="top_flex">
          <div
            @click="tabClick(item, index)"
            :class="index == tabindex ? 'item back' : 'item '"
            v-for="(item, index) in tabList"
            :key="index"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="date_flex_right">
          <div class="date_flex" v-if="tabindex != 1">
            <div
              @click="dateClick(index)"
              :class="dateIndex == index ? 'date_flex_item back' : 'date_flex_item'"
              v-for="(item, index) in dates"
              :key="index"
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div>
          <threeEcharts
            :dateIndex="dateIndex"
            :tabindex="tabindex"
            :chartInfo="chartInfo"
          ></threeEcharts>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import {
  getAdminOverviewResult,
  getTwoLevelUnitByUserId,
  getRequirementCurveChart
} from '../../../api/cockpit.js'
import threeEcharts from './threeEcharts.vue'
export default {
  components: {
    threeEcharts
  },
  props: {},
  data() {
    return {
      queryInfo: {
        dataType: 1,
        id: 0,
        timeType: 2
      },
      cockpitData: {},
      checkList: [],
      dateIndex: 1,
      dates: [
        {
          name: '本月'
        },
        {
          name: '本年'
        }
      ],
      chartInfo: {
        nameEchart: '（客户数/人）',
        chartType: 'line',
        type: 1,
        chartArr: [],
        img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-227242483Group 1142815023.png'
      },
      tabindex: 0,

      tabList: [
        {
          name: '客户数据',
          txt: '（客户数/人）',
          type: 1,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-227242483Group 1142815023.png'
        },
        {
          name: '客户经理',
          txt: '（经理数/人）',
          type: 0,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-265113436Group 1142814542.png'
        },
        {
          name: '走访数据',
          txt: '（走访数/次）',
          type: 2,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-295098173Group 1142814742.png'
        },
        {
          name: '走访需求',
          txt: '（需求数/个）',
          type: 1,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-362526488Group 1142815037.png'
        },
        {
          name: '预算费用',
          txt: '（费用/万元）',
          type: 2,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-397294558Group 1142814504.png'
        },
        {
          name: '案例数据',
          txt: '（案例数/个）',
          type: 1,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-446368351Group 1142815034.png'
        },
        {
          name: '潜在线索',
          txt: '（线索/个）',
          type: 2,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-468600986Group 1142815038.png'
        }
      ],
      leftList: []
    }
  },
  computed: {},
  created() {
    this.getAdminOverviewResult()
    this.getTwoLevelUnitByUserId()
  },
  mounted() {},
  destroyed() {},
  methods: {
    async getTwoLevelUnitByUserId() {
      const res = await getTwoLevelUnitByUserId()
      if (res.resultCode == 200) {
        let list = []
        this.leftList = res.data
        this.leftList.forEach((el, index) => {
          if (index < 3) {
            // 没有id为新增

            this.queryInfo.id = el.id
            getRequirementCurveChart(this.queryInfo).then((response) => {
              if (response.resultCode === 200) {
                this.$set(el, 'list', response.data)
                if (this.queryInfo.dataType != 2) {
                  el.list.forEach((o) => {
                    let array = o.time.split('-')

                    if (array.length == 2) {
                      const monthMap = {
                        '01': '一月',
                        '02': '二月',
                        '03': '三月',
                        '04': '四月',
                        '05': '五月',
                        '06': '六月',
                        '07': '七月',
                        '08': '八月',
                        '09': '九月',
                        10: '十月',
                        11: '十一月',
                        12: '十二月'
                      }
                      const dashIndex = o.time.indexOf('-') // 找到第一个 "-" 的位置
                      o.time = o.time.substring(dashIndex + 1)
                      o.time = monthMap[o.time]
                    } else {
                      const dashIndex = o.time.indexOf('-') // 找到第一个 "-" 的位置
                      o.time = o.time.substring(dashIndex + 1)
                    }
                  })
                }
                this.$set(
                  el,
                  'num',
                  el.list.reduce((acc, item) => {
                    return acc + item.count || 0
                  }, 0)
                )
                list.push(el)
              
              }
            })

            this.checkList.push(el.id)
          }
        })
       
        this.chartInfo.chartArr = list
      
        
      }
    },
    async getRequirementCurveChart(item) {
      this.queryInfo.id = item.id
      const res = await getRequirementCurveChart(this.queryInfo)
      if (res.resultCode == 200) {
        this.$set(item, 'list', res.data)
        if (this.queryInfo.dataType != 2) {
          item.list.forEach((o) => {
            let array = o.time.split('-')

            if (array.length == 2) {
              const monthMap = {
                '01': '一月',
                '02': '二月',
                '03': '三月',
                '04': '四月',
                '05': '五月',
                '06': '六月',
                '07': '七月',
                '08': '八月',
                '09': '九月',
                10: '十月',
                11: '十一月',
                12: '十二月'
              }
              const dashIndex = o.time.indexOf('-') // 找到第一个 "-" 的位置
              o.time = o.time.substring(dashIndex + 1)
              o.time = monthMap[o.time]
            } else {
              const dashIndex = o.time.indexOf('-') // 找到第一个 "-" 的位置
              o.time = o.time.substring(dashIndex + 1)
            }
          })
        }
        this.$set(
          item,
          'num',
          item.list.reduce((acc, item) => {
            return acc + item.count || 0
          }, 0)
        )
        this.chartInfo.chartArr.push(item)
      }
    },
    async getAdminOverviewResult() {
      const res = await getAdminOverviewResult()
      if (res.resultCode == 200) {
        this.cockpitData = res.data
      }
    },
    //切换年月
    dateClick(index) {
      this.dateIndex = index
      this.queryInfo.timeType = index + 1
      this.chartInfo.chartArr = []
      this.getTwoLevelUnitByUserId()
    },
    tabClick(item, index) {
      this.queryInfo.dataType = index + 1
      this.tabindex = index
      this.chartInfo.nameEchart = item.txt
      this.chartInfo.type = item.type
      this.chartInfo.img = item.img
      if (index == 1) {
        this.chartInfo.chartType = 'bar'
      } else {
        this.chartInfo.chartType = 'line'
      }

      let copiedArray = JSON.parse(JSON.stringify(this.chartInfo.chartArr))
      this.chartInfo.chartArr = []
      copiedArray.forEach((el) => {
        this.getRequirementCurveChart(el)
      })
    },

    listchange(val) {
      // 初始化结果数组
      const matchedArray = [] // 存储id相同的元素
      const unmatchedArray = [] // 存储id不同的元素

      // 遍历array1
      this.chartInfo.chartArr.forEach((item1) => {
        const item2 = val.find((item2) => item2 === item1.id) // 在array2中查找匹配的id
        if (item2) {
          // 如果找到匹配的id，将两者都添加到matchedArray
          matchedArray.push({ array1: item1, array2: item2 })
        } else {
          // 如果没有找到匹配的id，将array1中的元素添加到unmatchedArray
          unmatchedArray.push(item1)
        }
      })

      // 遍历array2，检查是否有未匹配的元素
      val.forEach((item2) => {
        const item1 = this.chartInfo.chartArr.find((item1) => item1.id === item2)
        if (!item1) {
          // 如果没有找到匹配的id，将array2中的元素添加到unmatchedArray
          unmatchedArray.push(item2)
        }
      })

      let flag = this.hasObject(unmatchedArray)
      if (!flag) {
        const mergedArray = unmatchedArray.map((item1) => {
          const item2 = this.leftList.find((item2) => item2.id === item1)

          return item2 // 如果没有匹配项，返回原对象
        })
        mergedArray.forEach((el) => {
          this.getRequirementCurveChart(el)
        })
      } else {
        const idsInArray1 = unmatchedArray.map((item) => item.id)
        this.chartInfo.chartArr = this.chartInfo.chartArr.filter(
          (item) => !idsInArray1.includes(item.id)
        )
      }
    },
    hasObject(arr) {
      return arr.some((item) => typeof item === 'object' && item !== null)
    },
    //客户企业
    companyClick(isred) {
      if (isred == 2) {
        this.$router.push({
          path: '/aidedmanagement/case',
          query: {
            isred
          }
        })
      } else if (isred == 1) {
        this.$router.push({
          path: '/aidedmanagement/service/indedx',
          query: {
            isred
          }
        })
      } else if (isred == 4) {
        this.$router.push({
          path: '/enterpriseCustomer'
        })
      } else if (isred == 3) {
        this.$router.push({
          path: '/aidedmanagement/clue'
        })
      }
    },
    vitisClick() {
      this.$router.push({
        path: '/service/statistical'
      })
    },
    cockpiturl() {
      this.$router.push({
        path: '/service/demand'
      })
    },
    cockpitClick() {
      this.$router.push({
        path: '/customermanager'
      })
    }
  }
}
</script>
    
    <style lang="less" scoped>
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 0.0625rem solid #dcdfe6;
  border-radius: 0.125rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background-color: #fff;
  z-index: 1;
}
::v-deep .el-checkbox {
  color: #606266;
  font-weight: 500;
  font-size: 0.875rem;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  margin-right: 1.875rem;
  margin: 10px 0px;
}
.three_cai {
  height: calc(100vh - 130px);
  background: #eff6ff;
  padding: 15px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .right_name {
    font-size: 16px;
    padding-right: 15px;
    position: relative;
    top: 5px;
  }
  .login_info {
    display: flex;
    align-items: center;
    padding-left: 16px;

    img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }

    .info {
      font-size: 30px;
    }
  }

  .right {
    height: 78px;
    display: flex;

    .income {
      height: 100%;
      margin-right: 60px;

      .income_title {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: left;
        color: #ff6b00;

        :nth-child(1) {
          width: 13px;
          height: 13px;
        }
      }

      .income_money {
        font-size: 12px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
        color: #ff6b00;

        .money_icon {
          font-size: 28px;
          font-weight: 600;
          line-height: 44.8px;
          text-align: left;
        }
      }

      .income_order {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
        color: #999;
      }
    }

    .reporting_success {
      height: 100%;
      margin-right: 60px;
      color: #09121f;

      .reporting_title {
        .report_icon {
          font-size: 13px;
        }

        .report_title {
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
        }
      }

      .reporting_num {
        font-size: 12px;
        font-weight: 600;
        line-height: 16.8px;

        span {
          font-size: 28px;
          font-weight: 600;
          line-height: 44.8px;
        }
      }

      .reporting_order {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        color: #999;
      }
    }
  }
}
.dataTxt {
  margin-top: 16px;
  .ul_flex {
    width: 100%;
    height: 180px;

    padding: 0;
    display: flex;
    justify-content: space-between;
    .ul_flex_up {
      width: 25%;
      display: flex;
      flex-direction: column;
    }
    .li_center {
      height: 80px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      margin: 0px 15px 20px 0px;
      border-radius: 4px;
      background: #fff;
      cursor: pointer;
      box-shadow: 0 20px 20px #d7e7ff, /* 主阴影 */ 0 2px 5px #d7e7ff; /* 辅助阴影 */
    }
    .ul_flex_center {
      display: flex;
      flex-direction: column;
      width: 740px !important;
      background: #fff;
      margin-right: 15px;
      padding: 10px 40px;
      border-radius: 4px;
      .bottom_flex {
        display: flex;
        justify-content: space-between;
      }
      .li_two {
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        border-radius: 4px;
        background: #f2fbff;
        cursor: pointer;
      }
      .li_two_bottom {
        width: 46%;
        height: 75px;
        padding: 0px 0 0px 20px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        background: #f2fbff;
        cursor: pointer;
      }
    }

    .icon {
      width: 61px;
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 5px;
      margin-right: 20px;

      i {
        // width: 32px;
        // height: 28px;
        font-size: 32px;
      }
    }

    .icon_img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .data_info {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        font-weight: 550;
        color: #333;
      }

      .numInfo {
        .num {
          font-size: 24px;
          font-weight: 600;
          line-height: 33.6px;

          margin-left: 12px;
        }
        .unit {
          font-size: 14px;
          margin-left: 2px;
        }
        .color1 {
          color: #458bff;
        }
        .color2 {
          color: #65d7ff;
        }
        .color3 {
          color: #01caae;
        }
        .color4 {
          color: #8967ea;
        }
        .color5 {
          color: #5b75ff;
        }
        .color6 {
          color: #45c1d2;
        }
        .color7 {
          color: #ffa14b;
        }

        .add {
          font-size: 12px;
          font-weight: 400;
          line-height: 16.8px;
          color: #ff6c66;
        }

        .addNum {
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          color: #ff6c66;
          margin-left: 6px;
        }

        .addIcon {
          color: #ff6c66;
          margin-left: 6px;

          i {
            font-size: 17px;
          }
        }
      }

      .numInfo:hover {
        .num {
          color: #448aff;
        }
      }
    }
  }

  ul li:nth-child(3) .icon {
    background: #f4b667;
  }

  ul li:nth-child(4) .icon {
    background: #9a7bf2;
  }

  ul li:nth-child(5) .icon {
    background: #609bf0;
  }
}
.context_flex {
  height: calc(100vh - 415px);
  background: #fff;
  margin-top: 20px;
  display: flex;

  padding: 20px 15px;
  .context_flex_left {
    width: 380px;

    .auto_checkgroud {
      margin-top: 10px;
      height: calc(100vh - 430px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }

      /* 滚动条里面的滑块 */

      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
      .label_flex {
        display: flex;
        align-items: center;
      }
      .left_back {
        width: 22px;
        height: 16px;
        background: #ff6262;
        border-radius: 2px;
        margin-right: 2px;
      }
    }
    .context_flex_left_top {
      display: flex;
      align-items: center;
      color: #448aff;
      font-size: 16px;
      img {
        width: 15px;
        height: 15px;
        margin-left: 2px;
      }
    }
  }
  .context_flex_right {
    .date_flex_right {
      display: flex;
      justify-content: right;
    }
    .date_flex {
      display: flex;
      justify-content: right;
      margin-top: 20px;
      margin-right: 20px;
      height: 34px;
      width: 94px;
      border-radius: 4px;
      background: #f4f4f4;
      align-items: center;
      cursor: pointer;
      .date_flex_item {
        width: 45px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .back {
        background: #85b6ff;
        color: #fff;
      }
    }
    .top_flex {
      display: flex;
      cursor: pointer;
      .item {
        width: 130px;
        height: 44px;
        border-radius: 4px;
        background: #f4f4f4;
        margin-right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
      }
      .back {
        background: #4e93fb;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .left_border {
    height: 470px;
    background: #efefef;
    width: 1px;
    margin: 30px 20px;
  }
}
</style>
    