<template>
  <div>
    <div class="top">
      <div class="login_info">
        <img src="../../../assets/image/Group 2821.png" alt="" />
        <div class="info">
          <h2>HI，{{ userName }},欢迎登录贵阳智参</h2>
          <div class="time">
            <span class="info_icon">
              <i class="el-icon-refresh"></i>
            </span>
            <span>数据最近更新时间：{{ cockpitData.time }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="dataTxt">
      <div class="ul_flex">
        <div class="ul_flex_up">
          <div @click="companyClick('4')" class="li_center">
            <div class="icon">
              <img class="icon_img" src="../../../assets/image/Group 1142815023.png" alt="" />
            </div>
            <div class="data_info">
              <span class="title">客户数据（个）</span>
              <div class="numInfo">
                <span class="num">{{
                  cockpitData.companyNum == null ? 0 : cockpitData.companyNum
                }}</span>
                <span>
                  <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.companyWeekNum == null ? 0 : cockpitData.companyWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div @click="cockpitClick()" class="li_center">
            <div class="icon">
              <img class="icon_img" src="../../../assets/image/Group 1142814542.png" alt="" />
            </div>
            <div class="data_info">
              <span class="title">客户经理（个）</span>
              <div class="numInfo">
                <span class="num">{{ cockpitData.userNum == null ? 0 : cockpitData.userNum }}</span>

                <span>
                  <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.userWeekNum == null ? 0 : cockpitData.userWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="ul_flex_center">
          <div @click="vitisClick()" class="li_two">
            <div class="icon">
              <img class="icon_img" src="../../../assets/image/Group 1142814742.png" alt="" />
            </div>
            <div class="li_two_info">
              <span class="li_two_info_title">走访数据（个）</span>
              <div class="li_two_info_numInfo">
                <span class="li_two_info_num">{{
                  cockpitData.visitNum == null ? 0 : cockpitData.visitNum
                }}</span>
                <span>
                  <span class="li_two_info_add">本周新增</span>
                  <span class="li_two_info_addNum">{{
                    cockpitData.visitWeekNum == null ? 0 : cockpitData.visitWeekNum
                  }}</span>
                  <span class="li_two_info_addIcon">
                    <i class="el-icon-top"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="bottom_flex">
            <div class="li_two_bottom">
              <div class="icon">
                <img class="icon_img" src="../../../assets/image/Group 1142814504.png" alt="" />
              </div>
              <div class="data_info">
                <span class="title">预算费用（万）</span>
                <div class="numInfo">
                  <span class="num">{{
                    cockpitData.totalBudget == null ? 0 : cockpitData.totalBudget
                  }}</span>
                  <span>
                    <span class="add">本周新增</span>
                    <span class="addNum">{{
                      cockpitData.weekBudget == null ? 0 : cockpitData.weekBudget
                    }}</span>
                    <span class="addIcon">
                      <i class="el-icon-top"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div @click="cockpiturl" class="li_two_bottom">
              <div class="icon">
                <img class="icon_img" src="../../../assets/image/Group 1142815037.png" alt="" />
              </div>
              <div class="data_info">
                <span class="title">走访需求（个）</span>
                <div class="numInfo">
                  <span class="num">{{
                    cockpitData.visiRequirementNum == null ? 0 : cockpitData.visiRequirementNum
                  }}</span>
                  <span>
                    <span class="add">本周新增</span>
                    <span class="addNum">{{
                      cockpitData.visitRequirementWeekNum == null
                        ? 0
                        : cockpitData.visitRequirementWeekNum
                    }}</span>
                    <span class="addIcon">
                      <i class="el-icon-top"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ul_flex_up">
          <div @click="companyClick('2')" class="li_center">
            <div class="icon">
              <img class="icon_img" src="../../../assets/image/Group 1142815034.png" alt="" />
            </div>
            <div class="data_info">
              <span class="title">案例数据（个）</span>
              <div class="numInfo">
                <span class="num">{{ cockpitData.planNum == null ? 0 : cockpitData.planNum }}</span>
                <span>
                  <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.planWeekNum == null ? 0 : cockpitData.planWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div @click="companyClick('3')" class="li_center">
            <div class="icon">
              <img class="icon_img" src="../../../assets/image/Group 1142815038.png" alt="" />
            </div>
            <div class="data_info">
              <span class="title">潜在线索（个）</span>
              <div class="numInfo">
                <span class="num">{{
                  cockpitData.diagnosisNum == null ? 0 : cockpitData.diagnosisNum
                }}</span>
                <span>
                  <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.diagnosisWeekNum == null ? 0 : cockpitData.diagnosisWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data_echarts">
      <div class="data_echarts_item">
        <div class="title">客户经理维度</div>
      </div>
      <div class="data_echarts_item">
        <div class="title">企业客户维度</div>
      </div>
    </div>
    <div class="data_echarts">
      <div class="data_echarts_ranging">
        <div class="data_echarts_ranging_top">
          <div class="data_echarts_ranging_top_title">
            <span>走访榜单TOP</span>
            <img src="../../../assets/image/workbench/ranging.png" />
          </div>
          <div class="data_echarts_ranging_top_item">
            <div class="data_echarts_ranging_top_item_more" @click="seeMore(0)">查看更多 >></div>
            <div class="data_echarts_ranging_top_item_date">
              <div
                class="day"
                @click="Clickranging(1)"
                :class="rangingActive == '1' ? 'date_active' : ''"
              >
                天
              </div>
              <div
                class="week"
                @click="Clickranging(2)"
                :class="rangingActive == '2' ? 'date_active' : ''"
              >
                周
              </div>
              <div
                class="mouth"
                @click="Clickranging(3)"
                :class="rangingActive == '3' ? 'date_active' : ''"
              >
                月
              </div>
            </div>
          </div>
        </div>
        <div class="data_echarts_ranging_bottom">
          <div class="top_three" v-if="rangingTable.length ==3">
            <div class="top_three_item two">
              <img src="../../../assets/image/workbench/ranging2.png" alt="" class="img1" />
              <img
                v-if="rangingTable[1].customerHead"
                :src="rangingTable[1].customerHead"
                alt=""
                class="profile"
              />
              <img v-else src="../../../assets/image/wait-set-manager.png" alt="" class="profile" />
              <img src="../../../assets/image/workbench/num2.png" alt="" class="ranging" />
              <h2>{{ rangingTable[1].customerName }}</h2>
              <div class="item_list">
                <span class="left">走访次数</span>
                <span class="right"
                  ><em>{{ rangingTable[1].visitNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">走访需求</span>
                <span class="right"
                  ><em>{{ rangingTable[1].visiRequirementNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">最近走访时间 </span>
                <span class="right">{{ rangingTable[1].time }}</span>
              </div>
            </div>
            <div class="top_three_item one">
              <img src="../../../assets/image/workbench/ranging1.png" alt="" class="img1" />
              <img
                v-if="rangingTable[0].customerHead"
                :src="rangingTable[0].customerHead"
                alt=""
                class="profile"
              />
              <img v-else src="../../../assets/image/wait-set-manager.png" alt="" class="profile" />
              <img src="../../../assets/image/workbench/num1.png" alt="" class="ranging" />
              <h2>{{ rangingTable[0].customerName }}</h2>
              <div class="item_list">
                <span class="left">走访次数</span>
                <span class="right"
                  ><em>{{ rangingTable[0].visitNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">走访需求</span>
                <span class="right"
                  ><em>{{ rangingTable[0].visiRequirementNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">最近走访时间 </span>
                <span class="right">{{ rangingTable[0].time }}</span>
              </div>
            </div>
            <div class="top_three_item three">
              <img src="../../../assets/image/workbench/ranging3.png" alt="" class="img1" />
              <img class="profile" v-if="rangingTable[2].sex==0" src="https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png" alt="" />
                  <img class="profile" v-else src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-419447711Group%203779.png" alt="" />
                    <img src="../../../assets/image/workbench/num3.png" alt="" class="ranging" />
              <h2>{{ rangingTable[2].customerName }}</h2>
              <div class="item_list">
                <span class="left">走访次数</span>
                <span class="right"
                  ><em>{{ rangingTable[2].visitNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">走访需求</span>
                <span class="right"
                  ><em>{{ rangingTable[2].visiRequirementNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">最近走访时间 </span>
                <span class="right">{{ rangingTable[2].time }}</span>
              </div>
            </div>
          </div>
          <div class="top_three" v-if="rangingTable.length ==2">
            <div class="top_three_item two">
              <img src="../../../assets/image/workbench/ranging2.png" alt="" class="img1" />
              <img class="profile" v-if="rangingTable[1].sex==0" src="https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png" alt="" />
                  <img class="profile" v-else src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-419447711Group%203779.png" alt="" />
                     <img src="../../../assets/image/workbench/num2.png" alt="" class="ranging" />
              <h2>{{ rangingTable[1].customerName }}</h2>
              <div class="item_list">
                <span class="left">走访次数</span>
                <span class="right"
                  ><em>{{ rangingTable[1].visitNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">走访需求</span>
                <span class="right"
                  ><em>{{ rangingTable[1].visiRequirementNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">最近走访时间 </span>
                <span class="right">{{ rangingTable[1].time }}</span>
              </div>
            </div>
            <div class="top_three_item one">
              <img src="../../../assets/image/workbench/ranging1.png" alt="" class="img1" />
              <img class="profile" v-if="rangingTable[0].sex==0" src="https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png" alt="" />
                  <img class="profile" v-else src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-419447711Group%203779.png" alt="" />
                 <img src="../../../assets/image/workbench/num1.png" alt="" class="ranging" />
              <h2>{{ rangingTable[0].customerName }}</h2>
              <div class="item_list">
                <span class="left">走访次数</span>
                <span class="right"
                  ><em>{{ rangingTable[0].visitNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">走访需求</span>
                <span class="right"
                  ><em>{{ rangingTable[0].visiRequirementNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">最近走访时间 </span>
                <span class="right">{{ rangingTable[0].time }}</span>
              </div>
            </div>

          </div>
          <div class="top_three" v-if="rangingTable.length ==1">

            <div class="top_three_item one">
              <img src="../../../assets/image/workbench/ranging1.png" alt="" class="img1" />
                <img class="profile" v-if="rangingTable[0].sex==0" src="https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png" alt="" />
                  <img class="profile" v-else src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-419447711Group%203779.png" alt="" />

              <img src="../../../assets/image/workbench/num1.png" alt="" class="ranging" />
              <h2>{{ rangingTable[0].customerName }}</h2>
              <div class="item_list">
                <span class="left">走访次数</span>
                <span class="right"
                  ><em>{{ rangingTable[0].visitNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">走访需求</span>
                <span class="right"
                  ><em>{{ rangingTable[0].visiRequirementNum }}</em
                  >次</span
                >
              </div>
              <div class="item_list">
                <span class="left">最近走访时间 </span>
                <span class="right">{{ rangingTable[0].time }}</span>
              </div>
            </div>

          </div>
          <div class="data_echarts_ranging_table">
            <el-table
              :data="rangTable"
              :header-cell-style="{ color: '#333333', 'font-size': '14px', 'font-weight': '400' }"
            >
              <el-table-column
                type="index"
                label="排名"
                align="center"
                width="60"

              >
              <template v-slot="{ row }" >
                  <span>{{ row.index }}</span>
                </template>
            </el-table-column>
              <el-table-column label="头像" align="center" width="60">
                <template v-slot="{ row }">
                  <img class="img_crie" v-if="row.sex==0" src="https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png" alt="" />
                  <img class="img_crie" v-else src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-419447711Group%203779.png" alt="" />

                </template>
              </el-table-column>
              <el-table-column prop="customerName" label="姓名" align="center" width="120">
                <template v-slot="{ row }">
                  <span>{{ row.customerName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="visitNum"
                label="走访次数"
                align="center"
                sortable
              ></el-table-column>
              <el-table-column
                prop="visiRequirementNum"
                label="走访需求"
                align="center"
                sortable
                width="180"
              ></el-table-column>
              <el-table-column
                prop="time"
                label="最近走访时间"
                align="center"
                sortable
                width="160"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="data_echarts_ranging">
        <div class="enterprise">
          <div class="enterprise_title">
            <span>企业榜单TOP</span>
            <img src="../../../assets/image/workbench/ranging.png" />
          </div>
          <div class="table_list">
            <div
              @click="typeClick(0)"
              class="table_list_item"
              :class="businessChatParams.type == '0' ? 'enterprise_active' : ''"
            >
              走访次数
            </div>
            <div
              @click="typeClick(1)"
              class="table_list_item"
              :class="businessChatParams.type == '1' ? 'enterprise_active' : ''"
            >
              走访需求
            </div>
            <div
              @click="typeClick(2)"
              class="table_list_item"
              :class="businessChatParams.type == '2' ? 'enterprise_active' : ''"
            >
              推送案例
            </div>
            <div
              @click="typeClick(3)"
              class="table_list_item"
              :class="businessChatParams.type == '3' ? 'enterprise_active' : ''"
            >
              潜在线索
            </div>
          </div>
          <div class="data_echarts_ranging_top_item_more" @click="seeMore(1)">查看更多 >></div>
        </div>
        <div class="data_echarts_ranging_enterprise">
          <ComponEcharts :businessChatParams="businessChatParams" :companyList="companyList" />
        </div>
      </div>
    </div>
    <el-dialog
      :title="typeTitle"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div class="border_left"></div>
      <div class="search_right">
        <el-input
          placeholder="请输入客户经理姓名"
          v-model="visitParams.keyword"
          class="input-with-select"
          clearable
          @clear="search"
        >
          <el-button slot="append" @click="search" type="primary">搜索</el-button>
        </el-input>
      </div>
      <ComponTable @sortChange="sortChange" :tableConfig="tableConfig" :tableData="TableList" />
      <div class="new_page">
        <el-pagination
          :current-page="visitParams.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="visitParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-dialog>
    <el-dialog
      :title="typeTitle"
      :visible.sync="dialogVisibleTab"
      width="50%"
      :before-close="handleClosea"
    >
      <div class="border_left"></div>
      <div class="search_right">
        <el-input
          placeholder="请输入企业名称"
          v-model="businessChatParams.keyword"
          class="input-with-select"
          clearable
          @clear="getBusinessVisitList"
        >
          <el-button slot="append" @click="getBusinessVisitList" type="primary">搜索</el-button>
        </el-input>
      </div>
      <tableList
        @sortChangeCompany="sortChangeCompany"
        :tableConfig="tableConfig"
        :tableData="companyList"
      />
      <div class="new_page">
        <el-pagination
          :current-page="businessChatParams.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="businessChatParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="companyToatl"
          @size-change="handleSizeChanges"
          @current-change="handleCurrentChanges"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ComponEcharts from './ComponEcharts.vue'
import ComponTable from './ComponTable.vue'
import tableList from './tableList.vue'
import tableData from '../configTable'

// api
import { getBusinessVisitList, getVisitList, getCockpitData } from '@/api/cockpit.js'

export default {
  components: {
    ComponTable,
    tableList,
    ComponEcharts
  },
  props: {},
  data() {
    return {
      rangTable: [],
      dialogVisibleTab: false,
      cockpitData: {},
      TableList: [],
      companyToatl: 0,
      dialogVisible: false,
      tableConfig: [], //表单配置数据
      componTableData: [], //组件表单数据
      componEcahrtsType: 1,
      typeTitle: '走访榜单',
      rangingTable: [], //走访榜单表格数据
      rangingActive: 1, // 1代表默认选中天 2代表周 3代表月
      enterpriseActive: 1, // 企业榜单选中
      companyList: [],
      // 企业榜单统计
      businessChatParams: {
        city: '',
        companyName: '',
        customerName: '',
        district: '',
        endTime: '',
        keyword: '',
        maxPrice: 0,
        minPrice: 0,
        orderByParam: 0,
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        timeType: '',
        type: 0
      },
      total: 0,
      // List
      visitParams: {
        city: '',
        companyName: '',
        customerName: '',
        district: '',
        endTime: '',
        keyword: '',
        maxPrice: 0,
        minPrice: 0,
        orderByParam: 0,
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        timeType: '0',
        type: ''
      }
    }
  },
  computed: {
    customerId() {
      return sessionStorage.getItem('customerId')
    },
    // 判断当前是使用的什么智参（浏阳、佛山、合作伙伴）
    zcName() {
      return JSON.parse(sessionStorage.getItem('zcName'))
    },
    userName() {
      return sessionStorage.getItem('userName')
    }
  },
  created() {
    this.getVisitList()
    this.getCockpitData() //获取工作台数据
    this.getBusinessVisitList()
  },
  mounted() {},
  destroyed() {},
  methods: {
    // 获取工作台数据
    async getCockpitData() {
      const res = await getCockpitData({
        // partnerId: this.partnerId,
        id: this.customerId || ''
      })
      if (res.resultCode == 200) {
        this.cockpitData = res.data
      }
    },
    sortChange(val) {
      if (val.prop === 'visitNum') {
        if (val.order === 'ascending') {
          this.visitParams.orderByParam = 1
        } else if (val.order === 'descending') {
          this.visitParams.orderByParam = 2
        } else {
          this.visitParams.orderByParam = 2
        }
      } else if (val.prop === 'visiRequirementNum') {
        if (val.order === 'ascending') {
          this.visitParams.orderByParam = 3
        } else if (val.order === 'descending') {
          this.visitParams.orderByParam = 4
        } else {
          this.visitParams.orderByParam = 4
        }
      } else if (val.prop === 'time') {
        if (val.order === 'ascending') {
          this.visitParams.orderByParam = 5
        } else if (val.order === 'descending') {
          this.visitParams.orderByParam = 6
        } else {
          this.visitParams.orderByParam = 6
        }
      }
      this.search()
    },
    sortChangeCompany(val) {
      if (val.prop === 'number') {
        if (val.order === 'ascending') {
          this.visitParams.orderByParam = 1
        } else if (val.order === 'descending') {
          this.visitParams.orderByParam = 2
        } else {
          this.visitParams.orderByParam = 2
        }
      } else if (val.prop === 'time') {
        if (val.order === 'ascending') {
          this.visitParams.orderByParam = 5
        } else if (val.order === 'descending') {
          this.visitParams.orderByParam = 6
        } else {
          this.visitParams.orderByParam = 6
        }
      }
      this.getBusinessVisitList()
    },
    Clickranging(index) {
      this.rangingActive = index
      this.visitParams.timeType = index - 1
      this.getVisitList()
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.visitParams.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.visitParams.pageNum = newCurrent
      this.search()
    },
    //监听页码变化
    handleCurrentChanges(newCurrent) {
      this.businessChatParams.pageNum = newCurrent
      this.getBusinessVisitList()
    },
    //监听pagesize变化
    handleSizeChanges(newSize) {
      this.businessChatParams.pageSize = newSize
      this.getBusinessVisitList()
    },

    async getVisitList() {
      const res = await getVisitList(this.visitParams)
      if (res.resultCode == 200) {
        this.TableList = res.data.list
        this.TableList.forEach((item,index)=>{
          this.$set(item,'index',index+1)
        })
        this.rangingTable = this.TableList.slice(0, 3)
        this.rangTable = this.TableList.slice(3, 6)
        this.total = res.data.total
        this.rangingTable.forEach((el) => {
          if (el.time) {
            el.time = el.time.split(' ')[1]
          }
        })
      }
    },
    async search() {
      const res = await getVisitList(this.visitParams)
      if (res.resultCode == 200) {
        this.TableList = res.data.list

        this.total = res.data.total
      }
    },
    async getBusinessVisitList() {
      const res = await getBusinessVisitList(this.businessChatParams)
      if (res.resultCode == 200) {
        this.companyList = res.data.list
        this.companyToatl = res.data.total
      }
    },
    typeClick(index) {
      this.businessChatParams.type = index
      this.getBusinessVisitList()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },
    handleClosea() {
      this.dialogVisibleTab = false
    },
    seeMore(row) {
      if (row === 0) {
        // 代表走访榜单点击查看更多
        this.componTableData = this.TableList

        this.tableConfig = tableData.rangingConfigData

        this.componEcahrtsType = 1
        this.search()
        this.dialogVisible = true
      } else {
        this.tableConfig = tableData.ConfigData

        const enterpriseActive = this.businessChatParams.type + 1

        const typeMapping = {
          1: 2,
          2: 3,
          3: 4,
          4: 5
        }
        const typeTitle = {
          1: '走访次数',
          2: '走访需求',
          3: '推送案例',
          4: '潜在线索'
        }

        this.typeTitle = typeTitle[enterpriseActive] || '企业榜单'
        this.tableConfig[2].label = this.typeTitle
        this.componEcahrtsType = typeMapping[enterpriseActive] || 1 // 默认值为 1
        this.dialogVisibleTab = true
      }
    },
    //客户企业
    companyClick(isred) {
      if (isred == 2) {
        this.$router.push({
          path: '/aidedmanagement/case',
          query: {
            isred
          }
        })
      } else if (isred == 1) {
        this.$router.push({
          path: '/aidedmanagement/service/indedx',
          query: {
            isred
          }
        })
      } else if (isred == 4) {
        this.$router.push({
          path: '/enterpriseCustomer'
        })
      }else if (isred == 3) {
        this.$router.push({
          path: '/aidedmanagement/clue'
        })
      }
    },
    vitisClick() {
      this.$router.push({
        path: '/service/statistical'
      })
    },
    cockpiturl() {
      this.$router.push({
        path: '/service/demand'
      })
    },
    cockpitClick() {
      this.$router.push({
        path: '/customermanager'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.search_right {
  width: 460px;
  margin-right: 20px;
  margin-bottom: 10px;
  float: right;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.border_left {
  width: 5px;
  height: 20px;
  background: #448aff;
  position: absolute;
  top: 22px;
  left: -0px;
}
.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12%;
  background: #fff;
  font-family: PingFang SC;
  border-radius: 4px;
  margin-bottom: 1%;
  padding: 15px 0px;
  .login_info {
    display: flex;
    align-items: center;
    padding-left: 16px;

    img {
      width: 78px;
      height: 78px;
      border-radius: 50%;
      margin-right: 24px;
      display: flex;
      align-items: center;
    }

    .info {
      height: 78px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4px 0;
      box-sizing: border-box;

      .info_icon {
        width: 16px;
        height: 16px;
        color: #8ab8ff;
        margin-right: 6px;
      }
    }

    .info h2 {
      font-size: 24px;
      font-weight: 500;
      color: #151515;
      padding: 0;
      margin: 0;
    }

    .time span:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
      color: #595959;
    }
  }

  .right {
    height: 78px;
    display: flex;

    .income {
      height: 100%;
      margin-right: 60px;

      .income_title {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: left;
        color: #ff6b00;

        :nth-child(1) {
          width: 13px;
          height: 13px;
        }
      }

      .income_money {
        font-size: 12px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
        color: #ff6b00;

        .money_icon {
          font-size: 28px;
          font-weight: 600;
          line-height: 44.8px;
          text-align: left;
        }
      }

      .income_order {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
        color: #999;
      }
    }

    .reporting_success {
      height: 100%;
      margin-right: 60px;
      color: #09121f;

      .reporting_title {
        .report_icon {
          font-size: 13px;
        }

        .report_title {
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
        }
      }

      .reporting_num {
        font-size: 12px;
        font-weight: 600;
        line-height: 16.8px;

        span {
          font-size: 28px;
          font-weight: 600;
          line-height: 44.8px;
        }
      }

      .reporting_order {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        color: #999;
      }
    }
  }
}
.data_echarts_ranging_table {
  .img_crie {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
}
.dataTxt {
  .ul_flex {
    width: 100%;
    height: 220px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    .ul_flex_up {
      width: 25%;
      display: flex;
      flex-direction: column;
    }
    .li_center {
      padding: 21px 0 21px 21px;
      display: flex;
      margin: 0px 15px 15px 0px;
      border-radius: 4px;
      background: #fff;
      cursor: pointer;
    }
    .ul_flex_center {
      display: flex;
      flex-direction: column;
      width: 740px !important;
      background: #fff;
      margin-right: 15px;
      padding: 10px 40px;
      border-radius: 4px;
      .bottom_flex {
        display: flex;
        justify-content: space-between;
      }
      .li_two {
        padding: 14px 0 14px 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        border-radius: 4px;
        background: #f2fbff;
        cursor: pointer;
        .li_two_info {
          display: flex;
          align-items: center;
          .li_two_info_title {
            font-size: 16px;
            font-weight: 550;
            color: #333;
          }

          .li_two_info_numInfo {
            .li_two_info_num {
              font-size: 24px;
              font-weight: 600;
              line-height: 33.6px;
              color: #333;
              margin-right: 12px;
            }

            .li_two_info_add {
              font-size: 12px;
              font-weight: 400;
              line-height: 16.8px;
              color: #ff6c66;
            }

            .li_two_info_addNum {
              font-size: 16px;
              font-weight: 400;
              line-height: 22.4px;
              color: #ff6c66;
              margin-left: 6px;
            }

            .li_two_info_addIcon {
              color: #ff6c66;
              margin-left: 6px;

              i {
                font-size: 17px;
              }
            }
          }

          .numInfo:hover {
            .num {
              color: #448aff;
            }
          }
        }
      }
      .li_two_bottom {
        width: 47%;
        padding: 16px 0 16px 16px;
        display: flex;
        border-radius: 4px;
        background: #f2fbff;
        cursor: pointer;
      }
    }

    .icon {
      width: 61px;
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 5px;
      margin-right: 20px;

      i {
        // width: 32px;
        // height: 28px;
        font-size: 32px;
      }
    }

    .icon_img {
      width: 70px;
      height: 70px;
    }

    .data_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 16px;
        font-weight: 550;
        color: #333;
      }

      .numInfo {
        .num {
          font-size: 24px;
          font-weight: 600;
          line-height: 33.6px;
          color: #333;
          margin-right: 12px;
        }

        .add {
          font-size: 12px;
          font-weight: 400;
          line-height: 16.8px;
          color: #ff6c66;
        }

        .addNum {
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          color: #ff6c66;
          margin-left: 6px;
        }

        .addIcon {
          color: #ff6c66;
          margin-left: 6px;

          i {
            font-size: 17px;
          }
        }
      }

      .numInfo:hover {
        .num {
          color: #448aff;
        }
      }
    }
  }

  ul li:nth-child(3) .icon {
    background: #f4b667;
  }

  ul li:nth-child(4) .icon {
    background: #9a7bf2;
  }

  ul li:nth-child(5) .icon {
    background: #609bf0;
  }
}

.data_echarts {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .data_echarts_item {
    border-radius: 0.5rem;
    margin-right: 8px;
    background: #fff;
    width: 100%;
    height: 46px;
    line-height: 46px;
    box-sizing: border-box;
    padding: 0 20px;
  }

  & > .data_echarts_item:last-child {
    margin-right: 0;
    margin-left: 16px;
  }

  .data_echarts_item .title {
    color: #151515;
    font-size: 16px;
    font-weight: 550;
  }

  .data_echarts_ranging {
    border-radius: 0.5rem;
    margin-right: 8px;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;

    .data_echarts_ranging_top {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .data_echarts_ranging_top_title {
        flex: 1;
        align-items: center;
        display: flex;

        span {
          color: #151515;
          font-weight: 550;
          font-size: 16px;
          margin-right: 5px;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }

      .data_echarts_ranging_top_item {
        width: 30%;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: flex-end;

        .data_echarts_ranging_top_item_more {
          color: #4e93fb;
          font-size: 14px;
          font-weight: 400;
          margin-right: 20px;
          cursor: pointer;
        }

        .data_echarts_ranging_top_item_date {
          font-size: 12px;
          font-weight: 400;
          line-height: 16.8px;
          color: #333;
          display: flex;

          div {
            display: inline-block;
            width: 27px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 4px;
            cursor: pointer;
          }

          .date_active {
            background: #85b6ff;
            color: #fff;
          }
        }
      }
    }

    .data_echarts_ranging_bottom {
      width: 100%;
      box-sizing: border-box;
      padding: 50px 8px 30px 8px;

      .top_three {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;

        .top_three_item {
          display: flex;
          box-sizing: border-box;
          padding: 0 20px;
          border-radius: 2px;
          flex-direction: column;
          align-items: center;
          position: relative;
          flex: 1;

          .img1 {
            position: absolute;
            left: 50%;
            top: -40px;
          }

          .profile {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            margin-top: -25px;
          }

          .ranging {
            position: absolute;
            top: 10px;
            right: 10px;
          }

          h2 {
            margin: 4px 0 0 0;
            font-weight: 600;
            font-size: 16px;
          }

          .item_list {
            margin-top: 6px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .left {
              color: #333333;
              font-size: 14px;
              font-weight: 400;
            }

            .right {
              color: #333333;
              font-size: 12px;
              font-weight: 400;
              width: 80px;
              text-align: center;
              display: inline-block;

              em {
                color: #4e93fb;
                font-style: normal;
              }
            }
          }
        }

        .one {
          margin: 0 14px;
          height: 150px;
          background: linear-gradient(180deg, #ffedd0 0%, #fffef8 77.92%);

          h2 {
            margin-bottom: 20px;
          }
        }

        .two {
          margin-top: 10px;
          height: 140px;
          background: linear-gradient(180deg, #e1e1e1 0%, rgba(250, 250, 250, 0.16) 100%);

          h2 {
            margin-bottom: 10px;
          }
        }

        .three {
          margin-top: 20px;
          height: 130px;
          background: linear-gradient(180deg, #ffdecf 0%, rgba(254, 248, 245, 0.2) 100%);
        }
      }
    }
  }

  & > .data_echarts_ranging:last-child {
    margin-right: 0;
    margin-left: 16px;
  }
}

.enterprise {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .enterprise_title {
    width: 160px;
    align-items: center;
    display: flex;

    span {
      color: #151515;
      font-weight: 550;
      font-size: 16px;
      margin-right: 5px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .table_list {
    width: 300px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
    justify-content: center;
    background-color: #f6f6f6;

    .table_list_item {
      width: 72px;
      color: #333;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      border-radius: 4px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .enterprise_active {
      background-color: #4e93fb;
      color: #ffffff;
    }
  }

  .data_echarts_ranging_top_item_more {
    color: #4e93fb;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    cursor: pointer;
  }
}
</style>
